export const responsive = {
	data() {
		return {
			isMobile: false,
			isTablet: false,
			isDesktop: false
		}
	},
	created() {
		window.addEventListener('resize', this.updateDevices);
	},
	destroyed() {
		window.removeEventListener('resize', this.updateDevices);
	},
	mounted() {
		this.updateDevices()
	},
	methods: {
		updateDevices() {
			// const agent = navigator.userAgent
			const w = window.innerWidth
			this.isMobile = w <= 767 ? true : false
			this.isTablet = w > 767 && w <= 1024 ? true : false
			this.isDesktop = w > 1024 ? true : false
		}
	}
}
